<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                数据安全，系统稳定，非凸运营团队做客户背后坚定的力量
              </p>
              <div class="fst-italic mb-2">2022年9月3日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >团建活动</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/12/01-封面.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    非凸智能交易平台累计成交额呈直线上升趋势，日均成交额已超30亿，预计年底突破百亿。数据中心持续赋能，算法策略稳定跑赢，机构客户高速增长，团队规模迅速壮大......这一切离不开非凸每一位小伙伴的辛勤付出。
                  </p>
                   <p>
                    快乐工作，才能迸发出无限凝聚力、创造力。9月3日，非凸运营团队在今年疫情后迎来首次团建活动，希望营造团结协作、奋发向上的团队氛围。“数据安全，系统稳定，有我们在”，非凸运营团队在做客户背后坚定的力量，7*24小时全天候护航。
                  </p>
                   <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/12/02-数据中心.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    非凸科技自建数据中心，提供强大算力支持，高效迭代算法策略，以应对瞬息万变的金融市场。Rust全栈开发，保障系统安全及稳定；团队经验丰富，创造行业领先业绩。除了提供优质算法、增厚收益外，我们还为量化机构提供完善及时的服务。运营团队与工程师团队极力配合，进行实时账户监控，利用完善的风控体系和周密的报警模块辅助，及时发现并处理突发问题。
                  </p>
                  <h4 class="my-4">趣味游戏</h4>
                  <p>
                    友谊的比赛正式开始，参赛的热情，角逐的激情，无不彰显非凸是一家年轻有活力的金融科技公司。
                  </p>
                  <p>
                    “巨人脚步”
                  </p>
                  <p>
                    步调一致，统一指挥
                  </p>
                   <p>
                    是为了更高效的团队协同合作
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/12/03-巨人脚步.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    “摸石头过河”
                  </p>
                  <p>
                    平衡力，耐力
                  </p>
                   <p>
                    是为了提升团队敢于尝试、大胆挑战的勇气
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/12/04-摸石头过河.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    经过快乐的游戏角逐，该补充体力啦！大家一改刚才的顽皮童趣样，秒变星级大厨，各展“才艺”。欢声笑语伴随着袅袅炊烟，画面和谐而美好。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/12/05-厨艺.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                   <p>
                    青春不散场，快乐不打烊。一天，虽短暂，但充实。团建活动在欢乐的卡丁车、CS友谊赛中圆满结束。因为一起体验过、坚持过，我们的关系更紧密了，士气更高涨了，未来更笃定了！
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/12/06-活动.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    齐心协力，相互熟悉；彼此信任，并肩前行；激发潜力，超越对手。正青春的我们，正前行的非凸，已做好充足准备，迎接未来新挑战！
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News12",
};
</script>

<style></style>
